@charset "UTF-8";
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html {
  box-sizing: border-box;
  overflow-y: scroll;
  /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */ }

*,
::before,
::after {
  box-sizing: inherit; }

::before,
::after {
  text-decoration: inherit;
  /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

input, select {
  vertical-align: middle; }

input[type="button"], input[type="submit"] {
  -webkit-appearance: none; }

html {
  font-size: 62.5%; }

body {
  color: #000;
  font-family: Verdana, "游ゴシック", "游ゴシック体", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  line-height: 1.5;
  background-color: #fff; }

::-moz-selection, ::selection {
  background: #b3d4fc;
  text-shadow: none; }

h1 {
  font-weight: normal; }

h2 {
  font-weight: normal; }

h3 {
  font-weight: normal; }

h4 {
  font-weight: normal; }

h5 {
  font-weight: normal; }

h6 {
  font-weight: normal; }

a {
  color: #f00;
  text-decoration: none; }

li {
  list-style: none; }

button,
input,
select,
textarea {
  color: #000; }
  button:focus,
  input:focus,
  select:focus,
  textarea:focus {
    outline: none; }

textarea {
  resize: vertical; }

label,
input[type=submit] {
  cursor: pointer; }

/* ==========================================================================
   Remodal's necessary styles
   ========================================================================== */
/* Hide scroll bar */
html.remodal-is-locked {
  overflow: hidden;
  touch-action: none; }

/* Anti FOUC */
.remodal,
[data-remodal-id] {
  display: none; }

/* Necessary styles of the overlay */
.remodal-overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  display: none; }

/* Necessary styles of the wrapper */
.remodal-wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch; }

.remodal-wrapper:after {
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  content: ""; }

/* Fix iPad, iPhone glitches */
.remodal-overlay,
.remodal-wrapper {
  backface-visibility: hidden; }

/* Necessary styles of the modal dialog */
.remodal {
  position: relative;
  outline: none;
  text-size-adjust: 100%; }

.remodal-is-initialized {
  /* Disable Anti-FOUC */
  display: inline-block; }

html,
body {
  height: 100%; }

.main {
  height: 100%;
  padding: 5% 18%;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 640px) {
    .main {
      padding: 10px; } }

.new ul {
  max-width: 1224px;
  display: flex;
  flex-wrap: wrap;
  margin: -10px auto 0; }
  .new ul li {
    width: 25%;
    list-style: none;
    cursor: pointer; }
    @media screen and (max-width: 640px) {
      .new ul li {
        width: 33.3333%; } }
    .new ul li a {
      display: block;
      width: 100%;
      height: 100%; }
    .new ul li img {
      width: 100%;
      height: auto; }

.remodal {
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }
  .remodal_inner {
    width: 100%;
    padding: 5% 28%; }
    @media screen and (max-width: 640px) {
      .remodal_inner {
        padding: 10px; } }
    .remodal_inner img {
      width: 100%;
      max-width: 1000px;
      height: auto; }
  .remodal_contents {
    width: 100%;
    position: relative; }
    .remodal_contents .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 28px;
      height: 28px; }
    .remodal_contents .video video {
      width: 100%;
      height: auto; }
