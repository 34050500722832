html,
body{
  height: 100%;
}

.main{
  height: 100%;
  padding: 5% 18%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  @include media_query(){
  	padding: 10px;
  }
}




.new{
  ul{
    max-width: 1224px;
    display: flex;
    flex-wrap: wrap;
    margin: -10px auto 0;
    
    li{
      width: 25%;
      list-style: none;
      cursor: pointer;
      
      @include media_query(){
      	width: 33.3333%;
      }
      
      a{
        display: block;
        width: 100%;
        height: 100%;
      }
      
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}




.remodal{
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  
  background-color: #fff;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  &_inner{
    width: 100%;
    padding: 5% 28%;
    
    @include media_query(){
    	padding: 10px;
    }
    
    img{
      width: 100%;
      max-width: 1000px;
      height: auto;
    }
  }
  
  &_contents{
    width: 100%;
    position: relative;
    
    .close{
      position: absolute;
      right: 10px;
      top: 10px;
      width: 28px;
      height: 28px;
    }
    
    .video{
      video{
        width: 100%;
        height: auto;
      }
    }
  }
}






























